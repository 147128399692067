<template>
  <div class="grey lighten-3">
    <simple-header
      hasLeftChild
      :routes="[
        { title: 'خانه', url: '/' },
        { title: 'دسته بندی', url: '/categories' },
        { title: 'داستان ها' },
      ]"
      title="داستان ها"
    >
      <v-img
        :src="require('../../assets/icons/story.png')"
        class="sp-img white-icon mr-md-auto ml-md-0 mx-auto"
      />
    </simple-header>
    <v-container>
      <v-row class="mt-4" align="center">
        <v-col cols="12">
          <search-bar @onSubmit="onSubmit" x-large />
        </v-col>
      </v-row>

      <v-row class="mt-4" v-if="loading">
        <v-col
          lg="3"
          md="4"
          sm="6"
          cols="12"
          v-for="(product, i) in 16"
          :key="'skeleton' + i"
        >
          <v-skeleton-loader type="card-heading" class="moholand-shadow" />
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col
          sm="6"
          md="4"
          cols="12"
          v-for="(scenario, i) in scenarios"
          :key="i"
        >
          <scenario-item :scenario="scenario" />
        </v-col>
      </v-row>
      <my-paginate
        v-model="current_page"
        :value="current_page"
        :length="pagesLength"
        @onChange="onPageChanged"
      />
    </v-container>
  </div>
</template>

<script>
import MyPaginate from "../../components/MyPaginate.vue";
import SellerItem from "../../components/Pages/SellerItem.vue";
import SearchBar from "../../components/SearchBar.vue";
import MyAxios from "../../constants/MyAxios";
import ScenarioItem from "../../components/Pages/ScenarioItem.vue";
import SimpleHeader from "../../components/SimpleHeader.vue";

export default {
  components: { SellerItem, MyPaginate, SearchBar, ScenarioItem, SimpleHeader },
  data() {
    return {
      search: "",
      scenarios: [],
      loading: false,
      pagesLength: 1,
      path: null,
      total: null,
      current_page: +this.$route.query?.page || 1,
    };
  },
  mounted() {
    this.GetData();
  },
  methods: {
    GetData: function() {
      window.scrollTo({ top: 0 });
      if (!this.loading) this.loading = true;
      MyAxios.get("/scenarios/index", {
        params: {
          search: this.search || undefined,
          conditions: {
            status: 1,
          },
          page: this.$route.query?.page || 1,
        },
      })
        .then((response) => {
          // this.scenarios = response.data.data.filter((val) => !!val.seller);
          this.scenarios = response.data.data;
          this.pagesLength = response.data.last_page;
          this.path = response.data.path;
          this.total = response.data.total;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    onSubmit(search) {
      this.search = search;
      this.$router.push({
        name: "Scenarios",
        query: {
          name: search || undefined,
        },
      });
      this.GetData();
    },
    onPageChanged(page) {
      this.$router.push({
        name: "Scenarios",
        query: {
          ...this.$route.query,
          page,
        },
      });
      this.GetData();
    },
  },
};
</script>

<style scoped>
.sp-img {
  max-width: 150px;
  max-height: 150px;
  object-fit: contain;
}
</style>
